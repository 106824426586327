<template>
  <div id="app">
    <div>
      <el-select
        v-model="selectedService"
        class="dblock1"
        style="width: 30%"
        @change="fillterPartnerList"
        filterable
      >
        <el-option
          v-for="(item, index) in servicesList"
          :key="index"
          :value="item.name_eng"
          >{{ item.name_mon }}
        </el-option>
      </el-select>
      <el-select
        @change="selectedPartnerListData"
        v-if="selectedService"
        v-model="selectedPartner"
        class="dblock2"
        style="width: 20%"
        filterable
      >
        <el-option
          v-for="(item, index) in selectedPartnerList"
          :key="index"
          :value="item.full_name_eng"
          >{{ item.full_name_eng }}
        </el-option>
      </el-select>
    </div>
    <gmap-map
      :center="center"
      :zoom="14"
      :options="options"
      :draggable="false"
      style="width: 100%; height: 80vh"
    >
      <span>
        <div v-if="this.status && this.selectedService">
          <gmap-marker
            v-for="(item, index) in mapPartnerLIst.length > 0
              ? mapPartnerLIst
              : selectedPartnerList"
            :key="index"
            :position="item.pos"
            @click="getPolygon(item)"
            @mouseover="showByIndex = index"
            @mouseout="showByIndex = null"
          >
            <gmap-info-window :opened="showByIndex === index">
              <div class="">
                <div>
                  <div>
                    <div class="m-2">
                      <span style="font-weight: bold;">Харилцагчийн нэр: </span>
                      {{ item.full_name_eng }}
                    </div>
                  </div>
                  <div class="m-2">
                    <span style="font-weight: bold;">Харилцагчийн хаяг: </span>
                    {{ item.address }}
                    <br />
                  </div>
                </div>
              </div>
            </gmap-info-window>
          </gmap-marker>
          <div v-if="selectedPartner">
            <gmap-marker
              v-for="(item, index) in nearbyPartner.length > 0
                ? nearbyPartner
                : ''"
              :key="index"
              :position="item.pos"
              icon="http://maps.google.com/mapfiles/ms/icons/orange-dot.png"
              @mouseover="showByIndex = index"
              @mouseout="showByIndex = null"
            >
              <gmap-info-window :opened="showByIndex === index">
                <div class="">
                  <div>
                    <div>
                      <div class="m-2">
                        <span style="font-weight: bold;"
                          >Харилцагчийн нэр:
                        </span>
                        {{ item.full_name_eng }}
                      </div>
                    </div>
                    <div class="m-2">
                      <span style="font-weight: bold;"
                        >Харилцагчийн хаяг:
                      </span>
                      {{ item.address }}
                      <br />
                    </div>
                  </div>
                </div>
              </gmap-info-window>
            </gmap-marker>
          </div>
        </div>
        <div v-if="this.status && !this.selectedService">
          <gmap-marker
            v-for="(item, index) in mapPartnerLIst.length > 0
              ? mapPartnerLIst
              : partnerList"
            :key="index"
            :position="item.pos"
            @click="getPolygon(item)"
            @mouseover="showByIndex = index"
            @mouseout="showByIndex = null"
          >
            <gmap-info-window :opened="showByIndex === index">
              <div class="">
                <div>
                  <div>
                    <div class="m-2">
                      <span style="font-weight: bold;">Харилцагчийн нэр: </span>
                      {{ item.full_name_eng }}
                    </div>
                  </div>
                  <div class="m-2">
                    <span style="font-weight: bold;">Харилцагчийн хаяг: </span>
                    {{ item.address }}
                    <br />
                  </div>
                </div>
              </div>
            </gmap-info-window>
          </gmap-marker>
        </div>
        <div v-if="!this.status">
          <gmap-polygon
            v-for="(item, index) in polygon"
            :key="index"
            :paths="item.coordinates[0]"
            :options="optionsPoly"
          >
          </gmap-polygon>
          <gmap-marker
            :position="onePartner.pos"
            icon="https://i.imgur.com/PyVqOWI.png"
            @click="getPolygon"
            @mouseover="showByIndex = index"
            @mouseout="showByIndex = null"
          >
            <gmap-info-window :opened="showByIndex === index">
              <div class="">
                <div>
                  <div>
                    <div class="m-2">
                      <span style="font-weight: bold;">Харилцагчийн нэр: </span>
                      {{ item.full_name_eng }}
                    </div>
                  </div>
                  <div class="m-2">
                    <span style="font-weight: bold;">Харилцагчийн хаяг: </span>
                    {{ item.address }}
                    <br />
                  </div>
                </div>
              </div>
            </gmap-info-window>
          </gmap-marker>
        </div>
      </span>
    </gmap-map>
    <div v-if="!this.status">
      {{ onePartner.full_name_mon }}
      {{ onePartner.full_name_end }}
      {{ onePartner.service }}
    </div>
  </div>
</template>
<script>
import axios from "axios";
import service from "@/helpers/services.js";
export default {
  data() {
    return {
      status: true,
      partnerList: [],
      selectedPartnerList: [],
      mapPartnerLIst: [],
      dialogVisible: false,
      optionsPoly: {
        fillColor: "#000000",
        strokeColor: "#FF0000",
        strokeOpacity: 0.9,
        fillOpacity: 0.1
      },
      points: {
        lat: 47.9174,
        lng: 106.9137
      },
      center: {
        lat: 47.9174,
        lng: 106.9137
      },
      options: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: "greedy"
      },
      onePartner: {},
      polygon: [],
      servicesList: [],
      selectedService: null,
      selectedPartner: null,
      nearbyPartner: [],
      location: "",
      showByIndex: null
    };
  },
  created() {
    this.getPartnerLocation();
    this.getServices();
  },
  methods: {
    async getPolygon(item) {
      if (this.status) {
        const res = (
          await axios.get(
            "https://rest.toktok.mn/admin/getOutletLocationPolygon?id=" +
              item._id
          )
        ).data.data;
        res.pos = {
          lat: parseFloat(res.location.split(", ")[0]),
          lng: parseFloat(res.location.split(", ")[1])
        };
        this.onePartner = res;
        this.polygon = res.polygon.map(polygon => {
          polygon.coordinates[0] = polygon.coordinates[0].map(point => {
            return { lat: point[1], lng: point[0] };
          });
          return polygon;
        });
        this.status = false;
      } else {
        this.status = true;
      }
    },
    async getPartnerLocation() {
      const payload = {
        includeFields: ["location", "full_name_eng", "address"],
        pageSize: 1000,
        fromRecord: 0
      };
      service.getOutlets(payload).then(response => {
        response.data.data.map(el => {
          el.pos = {
            lat: parseFloat(el.location.split(", ")[0]),
            lng: parseFloat(el.location.split(", ")[1])
          };
          this.partnerList.push(el);
        });
      });
    },
    getServices() {
      service.getServices().then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    },
    fillterPartnerList(data) {
      if (this.selectedService) {
        if (this.selectedPartner === null || this.selectedPartner !== null) {
          this.selectedPartnerList = this.partnerList.filter(item =>
            item.service_list.includes(data)
          );
        } else {
          return this.partnerList.filter(
            item => item.full_name_eng === this.selectedPartner
          );
        }
      } else {
        this.selectedPartnerList = this.partnerList;
      }
    },
    async selectedPartnerListData(data) {
      let dataRes;
      this.nearbyPartner = [];
      this.mapPartnerLIst = this.selectedPartnerList.filter(
        item => item.full_name_eng === data
      );
      if (this.mapPartnerLIst) {
        this.location = this.mapPartnerLIst[0].location;
        await service
          .getNearbyPartnerLocation({ location: this.location })
          .then(locationRes => {
            dataRes = locationRes.data;
          });
        dataRes.map(el => {
          el.pos = {
            lat: parseFloat(el.location.split(", ")[0]),
            lng: parseFloat(el.location.split(", ")[1])
          };
        });
        this.nearbyPartner = dataRes;
      }
    }
  }
};
</script>
